import React, { useCallback, useMemo } from 'react';

import { EIssueStatus } from '@inbound/api';
import { LayoutJay } from '@lux-ds/content-layouts';
import { useTheme } from '@lux-ds/theming/dist/cjs/styles';
import Typography from '@lux-ds/typography';
import DescriptionIcon from '@mui/icons-material/Description';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import useItemIssueDetailsContext from 'pages/ItemIssues/hooks/useItemIssueDetailsContext';
import formatUserName from 'utils/formatUserName';

import Timeline, { ETimelineItemType, ITimelineItem } from './components/Timeline/Timeline';

const HistorySection: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation('itemIssues');
  const { selectedIssue } = useItemIssueDetailsContext();

  const getTimelineItem = useCallback(
    (statusId: EIssueStatus, createdBy: string) => {
      const initials = createdBy.charAt(0).toUpperCase();

      switch (statusId) {
        case EIssueStatus.ResolvedByWarehouse:
          return {
            color: theme.palette.rufous.main,
            node: initials,
            type: ETimelineItemType.SubmittedFromWarehouse
          };
        case EIssueStatus.ToDo:
          return {
            color: theme.palette.primary.shade[70],
            node: <DescriptionIcon sx={{ width: '12px' }} />,
            type: ETimelineItemType.Reasons
          };
        default:
          return { color: theme.palette.citlali.main, node: initials, type: ETimelineItemType.ChangeStatus };
      }
    },
    [theme.palette.citlali.main, theme.palette.primary.shade, theme.palette.rufous.main]
  );

  const historyItems = useMemo(
    (): ITimelineItem[] =>
      selectedIssue.comments?.map(item => {
        const { color, node, type } = getTimelineItem(item.statusId as EIssueStatus, item.createdBy as string);

        return {
          avatarColor: color,
          avatarNode: node,
          comment: item.comment,
          createdAt: moment(item.createdAtUtc).format('DD MMMM, YYYY').toString(),
          createdBy: formatUserName(item.createdBy as string),
          status: item.statusId as EIssueStatus,
          type,
          ...(type === ETimelineItemType.Reasons ? { reasons: item.reasons } : {})
        };
      }) || [],
    [getTimelineItem, selectedIssue]
  );

  return (
    <LayoutJay sx={{ height: '409px', width: '50%' }}>
      <Typography variant="bodyTextNormal" weight="semibold">
        {t('issueHistory.history')}
      </Typography>
      <LayoutJay scroll="auto">
        <Timeline items={historyItems} />
      </LayoutJay>
    </LayoutJay>
  );
};

export default HistorySection;
