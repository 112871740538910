import React, { useCallback, useMemo } from 'react';

import { EIssueStatus } from '@inbound/api';
import Button from '@lux-ds/button';
import { LayoutHerodias, LayoutJay } from '@lux-ds/content-layouts';
import Dropdown, { ISelectItem } from '@lux-ds/dropdown';
import Input from '@lux-ds/input';
import Typography from '@lux-ds/typography';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TItemIssuesStatusKeys, issueStatuses, itemIssueColor } from 'pages/ItemIssues/constants';
import useItemIssueDetailsContext from 'pages/ItemIssues/hooks/useItemIssueDetailsContext';
import camelize from 'utils/camelize';

const ActionSection: React.FC = () => {
  const { t } = useTranslation(['common', 'itemIssues']);
  const { form, isSubmitting, onSubmit, selectedIssue } = useItemIssueDetailsContext();

  const {
    formState: { errors }
  } = form;

  const issueStatusItems = useMemo(
    (): ISelectItem[] =>
      issueStatuses
        .filter(([key]) =>
          !selectedIssue.canBeSentToWarehouse ? key !== EIssueStatus[EIssueStatus.SentToWarehouse] : true
        )
        .map(([key, value]) => ({
          colorIndicator: itemIssueColor[value as EIssueStatus],
          id: key,
          key,
          name: t(`itemIssues:issueStatuses.${camelize(key) as TItemIssuesStatusKeys}`),
          value
        })),
    [selectedIssue.canBeSentToWarehouse, t]
  );

  const handleSubmit = useCallback(() => onSubmit(), [onSubmit]);

  return (
    <LayoutJay sx={{ width: '50%' }}>
      <Typography variant="bodyTextNormal" weight="semibold">
        {t('itemIssues:issueHistory.changeStatus')}
      </Typography>
      <LayoutJay>
        <Controller
          control={form.control}
          name="status"
          render={({ ref, ...controlProps }) => (
            <Dropdown
              {...controlProps}
              disabled={!selectedIssue.isEditable}
              error={!!errors.status}
              inputRef={ref}
              items={issueStatusItems}
              label=""
              sx={{ width: '200px' }}
            />
          )}
        />
        <Controller
          control={form.control}
          name="comments"
          render={({ ref, ...controlProps }) => (
            <Input
              {...controlProps}
              disabled={!selectedIssue.isEditable}
              error={!!errors.comments}
              inputRef={ref}
              label={`${t('common:comments')}${
                form.watch('status') === EIssueStatus[EIssueStatus.SentToWarehouse] ? '*' : ''
              }`}
              multiline
              rows={9}
            />
          )}
        />
        <LayoutHerodias align="right">
          <Button disabled={isSubmitting || !selectedIssue.isEditable} onClick={handleSubmit} variant="contained">
            {t('common:submit')}
          </Button>
        </LayoutHerodias>
      </LayoutJay>
    </LayoutJay>
  );
};

export default ActionSection;
