import React, { useCallback } from 'react';

import { EIssueStatus } from '@inbound/api';
import Chip from '@lux-ds/chip';
import { LayoutHerodias, LayoutJay } from '@lux-ds/content-layouts';
import Typography from '@lux-ds/typography';
import { useTranslation } from 'react-i18next';

import { TItemIssuesStatusKeys, itemIssueColor } from 'pages/ItemIssues/constants';
import camelize from 'utils/camelize';

import {
  StyledAvatar,
  StyledComment,
  StyledConnector,
  StyledContentHeader,
  StyledHerodias,
  StyledSeparator,
  StyledStatusIndicator,
  StyledTurnedInIcon
} from './Timeline.styles';

export enum ETimelineItemType {
  Reasons = 1,
  ChangeStatus = 2,
  SubmittedFromWarehouse = 3
}

export interface ITimelineItem {
  avatarColor?: string;
  avatarNode?: React.ReactNode;
  comment?: string;
  createdAt: string;
  createdBy: string;
  reasons?: string[];
  status: EIssueStatus;
  type: ETimelineItemType;
}

interface ITimelineProps {
  items: ITimelineItem[];
}

const Timeline: React.FC<ITimelineProps> = ({ items }) => {
  const { t } = useTranslation(['itemIssues']);

  const renderHeader = useCallback(
    (type: ETimelineItemType, status: EIssueStatus, reasons?: ITimelineItem['reasons']) => {
      switch (type) {
        case ETimelineItemType.ChangeStatus:
          return (
            <StyledContentHeader>
              <Typography variant="bodyTextSmall">{t('itemIssues:issueHistory.changedStatus')}</Typography>
              <StyledStatusIndicator
                color={itemIssueColor[status]}
                filled
                label={t(`itemIssues:issueStatuses.${camelize(EIssueStatus[status]) as TItemIssuesStatusKeys}`)}
                rounded
              />
            </StyledContentHeader>
          );
        case ETimelineItemType.Reasons:
          return (
            <StyledContentHeader>
              <Typography variant="bodyTextSmall">{t('itemIssues:issueHistory.reasons')}</Typography>
              {reasons?.map(reason => (
                <Chip key={reason} label={reason} squared />
              ))}
            </StyledContentHeader>
          );
        case ETimelineItemType.SubmittedFromWarehouse:
          return (
            <StyledContentHeader>
              <Typography variant="bodyTextSmall">{t('itemIssues:submittedFromWarehouse')}</Typography>
              <StyledTurnedInIcon />
            </StyledContentHeader>
          );
      }
    },
    [t]
  );

  return (
    <LayoutJay gap="s" sx={{ height: '100%' }}>
      {items.map((item, index) => (
        <StyledHerodias gap="s" key={index}>
          <StyledSeparator>
            <StyledAvatar $color={item.avatarColor}>{item.avatarNode}</StyledAvatar>
            {index < items.length - 1 && <StyledConnector />}
          </StyledSeparator>
          <LayoutJay gap="s" paddingBottom="m" sx={{ width: '100%' }}>
            {renderHeader(item.type, item.status, item.reasons)}
            <LayoutHerodias sx={{ justifyContent: 'space-between' }}>
              <LayoutHerodias gap="xs">
                <Typography variant="bodyTextSmall" weight="light">
                  {t('itemIssues:issueHistory.createdBy', { name: item.createdBy })}
                </Typography>
                {item.type === ETimelineItemType.Reasons && (
                  <StyledStatusIndicator
                    color={itemIssueColor[EIssueStatus.ToDo]}
                    filled
                    label={t('itemIssues:issueStatuses.toDo')}
                    rounded
                  />
                )}
              </LayoutHerodias>
              <Typography sx={{ minWidth: 'max-content' }} variant="caption">
                {item.createdAt}
              </Typography>
            </LayoutHerodias>
            {!!item.comment && <StyledComment variant="bodyTextNormal">{item.comment}</StyledComment>}
          </LayoutJay>
        </StyledHerodias>
      ))}
    </LayoutJay>
  );
};

export default Timeline;
