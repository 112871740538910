import React, { useMemo, useState } from 'react';

import { ESeverity } from '@inbound/api';
import { LayoutJay } from '@lux-ds/content-layouts';
import Typography from '@lux-ds/typography';
import CancelIcon from '@mui/icons-material/Cancel';
import WarningIcon from '@mui/icons-material/Warning';
import { useTranslation } from 'react-i18next';

import { StyledButton, StyledHerodias } from './MessageContainer.styles';

interface IMessageContainerProps {
  messages: string[];
  type: ESeverity;
}

const MessageContainer: React.FC<IMessageContainerProps> = ({ messages, type }) => {
  const { t } = useTranslation('authentication');

  const [isViewMore, setIsViewMore] = useState<boolean>(false);

  const isError = useMemo(() => type === ESeverity.Error, [type]);

  const icon = useMemo(
    (): JSX.Element => (isError ? <CancelIcon color="error" /> : <WarningIcon color="warning" />),
    [isError]
  );

  const title = useMemo(
    (): string => (isError ? t('list.errors.errorValidateList') : t('list.warningMessage')),
    [isError, t]
  );

  return (
    <StyledHerodias $isError={isError} gap="xs" padding="s">
      {icon}
      <LayoutJay gap="xxs" sx={{ width: '100%' }}>
        <Typography variant="bodyTextNormal">{title}</Typography>
        <LayoutJay gap="none" scroll={isViewMore} sx={{ maxHeight: '102px' }}>
          {isViewMore ? (
            messages.map((message, index) => <Typography key={index}>{message}</Typography>)
          ) : (
            <Typography variant="bodyTextNormal">{messages[0]}</Typography>
          )}
          {!isViewMore && messages.length > 1 && (
            <StyledButton variant="text" onClick={() => setIsViewMore(true)}>
              {t('list.seeMore')}
            </StyledButton>
          )}
        </LayoutJay>
      </LayoutJay>
    </StyledHerodias>
  );
};

export default MessageContainer;
