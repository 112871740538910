import { LoadingButton } from '@lux-ds/button';
import { LayoutHerodias } from '@lux-ds/content-layouts';
import { styled } from '@lux-ds/theming/dist/cjs/styles';

export const StyledHerodias = styled(LayoutHerodias, {
  shouldForwardProp: prop => prop !== '$isError'
})<{ $isError: boolean }>(({ $isError, theme }) => ({
  alignItems: 'flex-start',
  backgroundColor: $isError ? theme.palette.error.light : theme.palette.warning.light,
  borderRadius: theme.space.xxs
}));

export const StyledButton = styled(LoadingButton)({
  backgroundColor: 'transparent',
  textDecoration: 'underline',
  width: 'fit-content',

  '&:hover': {
    backgroundColor: 'transparent',
    textDecoration: 'underline'
  }
});
