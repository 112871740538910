import { EIssueStatus } from '@inbound/api';
import * as yup from 'yup';

export interface IItemIssueDetailSchema {
  comments?: string;
  status: keyof typeof EIssueStatus;
}

export const itemIssueDetailSchema = yup.object().shape({
  comments: yup.string().when('status', {
    is: EIssueStatus[EIssueStatus.SentToWarehouse],
    then: yup.string().required()
  }),
  status: yup.string().required()
});
