import React, { createContext, useCallback, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { EIssueStatus, GetProductIssuesQueryResponse, IssueGetModel, UpdateIssueRequest } from '@inbound/api';
import { useNotification } from '@lux-ds/notification';
import { UseFormMethods, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useApi from 'hooks/useApi';

import useItemIssuesContext from '../hooks/useItemIssuesContext';
import { IItemIssueDetailSchema, itemIssueDetailSchema } from '../schemas/IItemIssueDetailSchema';

interface IItemIssueDetailsContext {
  form: UseFormMethods<IItemIssueDetailSchema>;
  isLoading: boolean;
  isSubmitting: boolean;
  itemIssueDetails: GetProductIssuesQueryResponse;
  onSubmit: () => void;
  selectedIssue: IssueGetModel;
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
}

const ItemIssueDetailsContext = createContext<IItemIssueDetailsContext>({
  form: {} as IItemIssueDetailsContext['form'],
  isLoading: false,
  isSubmitting: false,
  itemIssueDetails: {},
  onSubmit: () => void 0,
  selectedIssue: {},
  selectedTab: '',
  setSelectedTab: () => void 0
});

const ItemIssueDetailsProvider: React.FC = ({ children }) => {
  const api = useApi();
  const { t } = useTranslation(['common', 'itemIssues']);
  const { enqueueNotification } = useNotification();
  const { isIssueHistoryModalOpen, selectedItemIssue } = useItemIssuesContext();

  const [isLoading, setIsLoading] = useState<IItemIssueDetailsContext['isLoading']>(false);
  const [isSubmitting, setIsSubmitting] = useState<IItemIssueDetailsContext['isSubmitting']>(false);
  const [itemIssueDetails, setItemIssueDetails] = useState<IItemIssueDetailsContext['itemIssueDetails']>({});
  const [selectedIssue, setSelectedIssue] = useState<IItemIssueDetailsContext['selectedIssue']>({});
  const [selectedTab, setSelectedTab] = useState<IItemIssueDetailsContext['selectedTab']>('');

  const form = useForm<IItemIssueDetailSchema>({
    defaultValues: {
      comments: '',
      status: EIssueStatus[EIssueStatus.ToDo] as keyof typeof EIssueStatus
    },
    mode: 'onSubmit',
    resolver: yupResolver(itemIssueDetailSchema),
    shouldUnregister: false
  });

  useEffect(() => {
    if (Object.keys(selectedIssue).length) {
      form.setValue(
        'status',
        selectedIssue.statusId === EIssueStatus.ResolvedByWarehouse
          ? EIssueStatus[EIssueStatus.SentToWarehouse]
          : selectedIssue.status
      );
    }
  }, [selectedIssue]);

  useEffect(() => {
    if (itemIssueDetails.issues?.length) {
      if (selectedTab) {
        const issue = itemIssueDetails.issues.find(({ id }) => id === selectedTab);

        setSelectedIssue(issue as IssueGetModel);
      } else {
        setSelectedTab(itemIssueDetails.issues[0].id as string);
      }
    }
  }, [itemIssueDetails, selectedTab]);

  useEffect(() => {
    return () => {
      setItemIssueDetails({});
      setSelectedTab('');
    };
  }, []);

  const handleGetItemIssueDetails = useCallback(() => {
    const handleGetItemIssueDetailsCallback = async () => {
      try {
        setIsLoading(true);
        const issueDetails = await api.issues_GetProductIssues(selectedItemIssue);

        setItemIssueDetails(issueDetails);
      } catch {
        enqueueNotification({ title: t('itemIssues:errors.errorGetItemIssueDetails') }, { variant: 'error' });
      } finally {
        setIsLoading(false);
      }
    };

    handleGetItemIssueDetailsCallback();
  }, [api, enqueueNotification, selectedItemIssue, t]);

  const handleSubmit = useCallback(
    (values: IItemIssueDetailSchema) => {
      const handleSubmitCallback = async () => {
        try {
          setIsSubmitting(true);

          const formValues: UpdateIssueRequest = {
            comment: values.comments,
            statusId: EIssueStatus[values.status]
          };

          await api.issues_Update(formValues, selectedIssue.id as string);
          form.reset();

          enqueueNotification({ title: t('itemIssues:submitSuccess') }, { variant: 'success' });

          handleGetItemIssueDetails();
        } catch {
          enqueueNotification({ title: t('itemIssues:errors.errorUpdateItemIssueDetails') }, { variant: 'error' });
        } finally {
          setIsSubmitting(false);
        }
      };

      handleSubmitCallback();
    },
    [api, enqueueNotification, form, handleGetItemIssueDetails, selectedIssue, t]
  );

  useEffect(() => {
    if (isIssueHistoryModalOpen && !!selectedItemIssue) {
      handleGetItemIssueDetails();
    }
  }, [handleGetItemIssueDetails, isIssueHistoryModalOpen, selectedItemIssue]);

  const handleSetSelectedTab = useCallback((tab: string) => setSelectedTab(tab), []);

  const onErrorSubmit = useCallback(
    () => enqueueNotification({ title: t('common:errors.errorInvalidForm') }, { variant: 'error' }),
    [enqueueNotification, t]
  );

  const onSubmit = useCallback(
    () => form.handleSubmit(handleSubmit, onErrorSubmit)(),
    [form, handleSubmit, onErrorSubmit]
  );

  return (
    <ItemIssueDetailsContext.Provider
      value={{
        form,
        isLoading,
        isSubmitting,
        itemIssueDetails,
        onSubmit,
        selectedIssue,
        selectedTab,
        setSelectedTab: handleSetSelectedTab
      }}
    >
      {children}
    </ItemIssueDetailsContext.Provider>
  );
};

export { ItemIssueDetailsProvider };

export default ItemIssueDetailsContext;
