import React, { useCallback, useMemo, useState } from 'react';

import Button, { LoadingButton } from '@lux-ds/button';
import { LayoutHerodias } from '@lux-ds/content-layouts';
import Dialog from '@lux-ds/dialog';
import Typography from '@lux-ds/typography';
import { useTranslation } from 'react-i18next';

import useAuthenticationListContext from 'pages/AuthenticationList/hooks/useAuthenticationListContext';

import FileDropzone, { IFile } from '../FileDropzone/FileDropzone';
import MessageContainer from '../MessageContainer/MessageContainer';

import { StyledJay } from './UploadResultsModal.styles';

interface IUploadResultsModalProps {
  onClose: () => void;
  onSubmit?: (file: File) => void;
}

const UploadResultsModal: React.FC<IUploadResultsModalProps> = ({ onClose }) => {
  const {
    handleResetUploadSubmissions,
    handleUploadResults,
    handleValidateUpload,
    isSubmissionUploading,
    isSubmissionValidated,
    isSubmissionValidating,
    uploadValidationMessages
  } = useAuthenticationListContext();
  const { t } = useTranslation(['authentication', 'common']);

  const [files, setFiles] = useState<IFile[]>([]);

  const acceptedExtensions = useMemo((): string => '.xls, .xlsx', []);
  const filesSupported = useMemo(
    (): string =>
      acceptedExtensions
        .toUpperCase()
        .replaceAll('.', '')
        .replace(/,(?=[^,]*$)/, ` ${t('common:or')}`),
    [acceptedExtensions, t]
  );

  const isDisabled = useMemo((): boolean => !files.length || !isSubmissionValidated, [files, isSubmissionValidated]);

  const handleClose = useCallback(() => {
    handleResetUploadSubmissions();
    onClose();
  }, [handleResetUploadSubmissions, onClose]);

  const handleUploadFile = useCallback((files: IFile[]) => handleValidateUpload(files[0].file), []);

  const handleRemoveFile = useCallback(() => handleResetUploadSubmissions(), [handleResetUploadSubmissions]);

  const handleSubmit = useCallback(
    () => handleUploadResults(files[0].file, handleClose),
    [files, handleClose, handleUploadResults]
  );

  return (
    <Dialog onClose={handleClose} open>
      <StyledJay>
        <Typography variant="h5" weight="semibold">
          {t('authentication:list.uploadResults')}
        </Typography>
        {uploadValidationMessages &&
          Object.entries(uploadValidationMessages).map(([key, value]) =>
            value.length ? <MessageContainer key={key} messages={value} type={Number(key)} /> : null
          )}
        <FileDropzone
          accept={acceptedExtensions}
          files={files}
          isUploading={isSubmissionValidating}
          onRemoveFile={handleRemoveFile}
          onUploadFile={handleUploadFile}
          setFiles={setFiles}
          subtitle={t('common:uploadFiles.filesSupported', { filesSupported })}
        />
        <LayoutHerodias align="right">
          <Button onClick={handleClose}>{t('common:cancel')}</Button>
          <LoadingButton
            loading={isSubmissionUploading}
            disabled={isDisabled}
            onClick={handleSubmit}
            variant="contained"
          >
            {t('authentication:list.updateList')}
          </LoadingButton>
        </LayoutHerodias>
      </StyledJay>
    </Dialog>
  );
};

export default UploadResultsModal;
