import React, { useCallback, useEffect } from 'react';

import Button from '@lux-ds/button';
import { LayoutHerodias } from '@lux-ds/content-layouts';
import IconButton from '@lux-ds/icon-button';
import { useNotification } from '@lux-ds/notification';
import Typography from '@lux-ds/typography';
import Delete from '@mui/icons-material/Delete';
import FilePresent from '@mui/icons-material/FilePresent';
import CircularProgress from '@mui/material/CircularProgress';
import { DropzoneProps, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import convertBytes, { EBytesMeasure } from 'utils/convertBytes';
import uuidGenerator from 'utils/uuidGenerator';

import { StyledDropzone, StyledHerodias, StyledIcon, StyledSubtitle } from './FileDropzone.styles';

export interface IFile {
  file: File;
  id: string;
}

interface IFileDropzoneProps extends DropzoneProps {
  className?: string;
  files: IFile[];
  isUploading?: boolean;
  onRemoveFile?: () => void;
  onUploadFile?: (file: IFile[]) => void;
  setFiles: (files: IFile[]) => void;
  subtitle?: string;
}

const FileDropzone: React.FC<IFileDropzoneProps> = ({
  accept,
  className,
  files,
  isUploading = false,
  maxSize = 5120,
  multiple,
  onRemoveFile,
  onUploadFile,
  setFiles,
  subtitle
}) => {
  const { t } = useTranslation(['authentication', 'common']);
  const { enqueueNotification } = useNotification();

  const onDropFile = useCallback(
    (filesUploaded: File[]) => {
      const newFiles = filesUploaded.map(file => ({ file, id: uuidGenerator() }));

      if (newFiles.length) {
        const uploadFiles = multiple ? [...files, ...newFiles] : newFiles;

        setFiles(uploadFiles);
        onUploadFile?.(uploadFiles);
      }
    },
    [files, multiple]
  );

  const handleRemoveFile = useCallback(
    (fileId: string) => {
      const filesUploaded = files.filter(({ id }) => fileId !== id);

      setFiles(filesUploaded);
      onRemoveFile?.();
    },
    [files]
  );

  const { fileRejections, getInputProps, getRootProps, open } = useDropzone({
    accept,
    maxSize: convertBytes(maxSize, EBytesMeasure.B, EBytesMeasure.KB),
    multiple,
    noClick: true,
    onDrop: onDropFile
  });

  useEffect(() => {
    if (fileRejections.length) {
      const [
        {
          errors,
          file: { name, size }
        }
      ] = fileRejections;
      const [{ code, message }] = errors;

      const errorMessage =
        code === 'too-many-files'
          ? message
          : `${name} - ${t('common:kiloBytes', { size: convertBytes(size, EBytesMeasure.KB) })}: ${message}`;

      enqueueNotification({ title: errorMessage }, { variant: 'error' });
    }
  }, [enqueueNotification, fileRejections, t]);

  return (
    <StyledDropzone gap="m" {...getRootProps({ className })}>
      {isUploading ? (
        <CircularProgress />
      ) : files.length ? (
        files.map(item => (
          <LayoutHerodias key={item.id}>
            <LayoutHerodias gap="s" sx={{ flex: 2 }}>
              <FilePresent />
              <Typography variant="label">{item.file.name}</Typography>
            </LayoutHerodias>
            <StyledHerodias gap="xxs">
              <Typography color="neutral.secondary.40" variant="label">
                {t('common:kiloBytes', {
                  size: convertBytes(item.file.size, EBytesMeasure.KB)
                })}
              </Typography>
              <IconButton onClick={() => handleRemoveFile(item.id)}>
                <Delete />
              </IconButton>
            </StyledHerodias>
          </LayoutHerodias>
        ))
      ) : (
        <>
          <input {...getInputProps()} />
          <LayoutHerodias gap="s" sx={{ justifyContent: 'center' }}>
            <StyledIcon />
            <LayoutHerodias gap="xs" sx={{ width: 'max-content' }}>
              <Typography color="neutral.secondary.40" variant="bodyTextNormal">
                <b>{t('common:uploadFiles.dragDropToUpload')}</b>
                <span> {t('common:or')}</span>
              </Typography>
              <Button onClick={open} variant="outlined">
                {t('common:browseFiles')}
              </Button>
            </LayoutHerodias>
          </LayoutHerodias>
          {subtitle && <StyledSubtitle variant="bodyTextSmall">{subtitle}</StyledSubtitle>}
        </>
      )}
    </StyledDropzone>
  );
};

export default FileDropzone;
