import { css, styled } from '@lux-ds/theming/dist/cjs/styles';
import Typography from '@lux-ds/typography';

export const StatusIndicatorContainer = styled('div', {
  shouldForwardProp: prop => prop !== '$filled' && prop !== '$rounded'
})<{ $filled: boolean; $rounded: boolean }>(
  ({ $filled, $rounded, theme }) => css`
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: ${theme.space.xs};
    max-width: max-content;
    overflow: hidden;

    ${$filled &&
    css`
      background-color: ${theme.palette.shade.light};
      padding: ${theme.space.xs} 12px;
    `}

    ${$rounded &&
    css`
      border-radius: ${theme.space.xxs};
    `}
  `
);

export const ColorIndicator = styled('div', {
  shouldForwardProp: prop => prop !== '$color'
})<{ $color?: string }>(
  ({ $color, theme }) => css`
    ${$color
      ? css`
          background-color: ${$color};
        `
      : css`
          background-color: ${theme.palette.primary.shade[100]};

          border: 1px solid ${theme.palette.primary.main};
        `}
    border-radius: 50%;
    flex-shrink: 0;
    height: ${theme.space.xs};
    width: ${theme.space.xs};
  `
);

export const LabelTypography = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
