import React, { createContext, useCallback, useEffect, useState } from 'react';

import {
  EItemStatus,
  ESeverity,
  ESubmissionStatus,
  GetSubmissionsQueryResponse,
  GetSubmissionsQueryResponsePagedListCounts,
  UploadSubmissionsFileResponse
} from '@inbound/api';
import {
  GridSortModel,
  IDateRangeValue,
  IFilterValue,
  IMultipleSelectOptionIdName,
  IMultipleSelectOptionKeyValue,
  IValueRangeValue
} from '@lux-ds/data-grid';
import { useNotification } from '@lux-ds/notification';
import { useTranslation } from 'react-i18next';

import useApi from 'hooks/useApi';

type ICount = { count: number; key: string };

type IFilterOptions = {
  brands: IMultipleSelectOptionIdName[];
  businessModels: IMultipleSelectOptionKeyValue[];
  itemStatuses: IMultipleSelectOptionIdName[];
  orderStatuses: IMultipleSelectOptionIdName[];
  partners: IMultipleSelectOptionIdName[];
  warehouse: IMultipleSelectOptionIdName[];
};

const countOptionsDefault: ICount[] = [
  { count: 0, key: '' },
  { count: 0, key: 'notSubmitted' },
  { count: 0, key: 'submitted' },
  { count: 0, key: 'withIssues' },
  { count: 0, key: 'completed' },
  { count: 0, key: 'inconclusive' }
];

const filterDefault = {
  availableSinceRange: {},
  brandIds: [],
  businessModels: [],
  itemStatusIds: [],
  orderStatusIds: [],
  partnerIds: [],
  statusUpdateRange: {},
  warehouseIds: []
};

type IUploadValidationMessage = Record<ESeverity, string[]>;

interface IAuthenticationListContext {
  countOptions: ICount[];
  filterOptions: IFilterOptions;
  filters: IFilterValue;
  handleResetUploadSubmissions: () => void;
  handleUploadResults: (file: File, onSuccess: () => void) => void;
  handleValidateUpload: (file: File) => void;
  isLoading: boolean;
  isSubmissionUploading: boolean;
  isSubmissionValidated: boolean;
  isSubmissionValidating: boolean;
  items: GetSubmissionsQueryResponse[];
  page: number;
  pageSize: number;
  search: string;
  setFilters: (newFilters: IFilterValue) => void;
  setPage: (newPage: number) => void;
  setPageSize: (newPageSize: number) => void;
  setSearch: (newSearch: string) => void;
  setSortModel: (sortModel: GridSortModel) => void;
  setStatusFilter: (event: React.SyntheticEvent<Element, Event>, newStatus: string) => void;
  sortModel: GridSortModel;
  statusFilter: string;
  totalItemsCount: number;
  uploadValidationMessages: IUploadValidationMessage | undefined;
}

const defaultFilterOptions: IFilterOptions = {
  brands: [],
  businessModels: [],
  itemStatuses: [],
  orderStatuses: [],
  partners: [],
  warehouse: []
};

const AuthenticationListContext = createContext<IAuthenticationListContext>({
  countOptions: [],
  filterOptions: defaultFilterOptions,
  filters: {},
  handleResetUploadSubmissions: () => void 0,
  handleUploadResults: () => void 0,
  handleValidateUpload: () => void 0,
  isLoading: true,
  isSubmissionUploading: false,
  isSubmissionValidated: false,
  isSubmissionValidating: false,
  items: [],
  page: 0,
  pageSize: 25,
  search: '',
  setFilters: () => void 0,
  setPage: () => void 0,
  setPageSize: () => void 0,
  setSearch: () => void 0,
  setSortModel: () => void 0,
  setStatusFilter: () => void 0,
  sortModel: [],
  statusFilter: '',
  totalItemsCount: 0,
  uploadValidationMessages: undefined
});

const AuthenticationListProvider: React.FC = ({ children }) => {
  const api = useApi();
  const { enqueueNotification } = useNotification();
  const { t } = useTranslation(['authentication', 'common']);

  const [filterOptions, setFilterOptions] = useState(defaultFilterOptions);
  const [filters, setFilters] = useState<IAuthenticationListContext['filters']>({});
  const [isLoading, setIsLoading] = useState<IAuthenticationListContext['isLoading']>(true);
  const [isSubmissionUploading, setIsSubmissionUploading] =
    useState<IAuthenticationListContext['isSubmissionUploading']>(false);
  const [isSubmissionValidated, setIsSubmissionValidated] =
    useState<IAuthenticationListContext['isSubmissionValidated']>(false);
  const [isSubmissionValidating, setIsSubmissionValidating] =
    useState<IAuthenticationListContext['isSubmissionValidating']>(false);
  const [items, setItems] = useState<IAuthenticationListContext['items']>([]);
  const [countOptions, setCountOptions] = useState<IAuthenticationListContext['countOptions']>(countOptionsDefault);
  const [page, setPage] = useState<IAuthenticationListContext['page']>(0);
  const [pageSize, setPageSize] = useState<IAuthenticationListContext['pageSize']>(25);
  const [search, setSearch] = useState<IAuthenticationListContext['search']>('');
  const [sortModel, setSortModel] = useState<IAuthenticationListContext['sortModel']>([]);
  const [statusFilter, setStatusFilter] = useState<IAuthenticationListContext['statusFilter']>('');
  const [totalItemsCount, setTotalItemsCount] = useState<IAuthenticationListContext['totalItemsCount']>(0);
  const [uploadValidationMessages, setUploadValidationMessages] =
    useState<IAuthenticationListContext['uploadValidationMessages']>();

  const getItems = useCallback(async () => {
    setIsLoading(true);

    try {
      const {
        availableSinceRange,
        brandIds,
        businessModels,
        itemStatusIds,
        orderStatusIds,
        partnerIds,
        statusUpdateRange,
        warehouseIds
      } = Object.entries(filters).reduce(
        (
          result: Record<string, IDateRangeValue | IValueRangeValue | IMultipleSelectOptionIdName['id'][]>,
          [key, values]
        ) => ({
          ...result,
          [key]: (Array.isArray(values) ? values.map(value => ('id' in value ? value.id : value.key)) : values) || null
        }),
        filterDefault
      );

      const { field: orderBy, sort: orderDirection } = sortModel[0] || {};

      const submissionsResponse = await api.submissions_GetAllPaged(
        page,
        pageSize,
        orderBy || null,
        orderDirection || null,
        (statusFilter as unknown as ESubmissionStatus) || null,
        warehouseIds as string[],
        brandIds as string[],
        partnerIds as number[],
        orderStatusIds as number[],
        itemStatusIds as EItemStatus[],
        businessModels as number[],
        search,
        (statusUpdateRange as IDateRangeValue)?.startDate || null,
        (statusUpdateRange as IDateRangeValue)?.endDate || null,
        (availableSinceRange as IDateRangeValue)?.startDate || null,
        (availableSinceRange as IDateRangeValue)?.endDate || null
      );
      const { counts, items = [], totalItems } = submissionsResponse;

      const countEntries = Object.entries(counts as GetSubmissionsQueryResponsePagedListCounts);
      const formattedCounts = countOptionsDefault.map(({ key }) => {
        const [, countValue] = countEntries.find(([countEntryKey]) => {
          return key === countEntryKey;
        }) || ['', counts?.allItems];

        return {
          count: countValue as number,
          key
        };
      });

      setItems(items);
      setIsLoading(false);
      setCountOptions(formattedCounts);
      setTotalItemsCount(totalItems || 0);
    } catch (err) {
      setItems([]);
      setIsLoading(false);
      setTotalItemsCount(0);
      enqueueNotification({ title: t('authentication:list.errors.errorGetAuthentications') }, { variant: 'error' });
    }
  }, [filters, page, pageSize, search, sortModel, statusFilter]);

  const getFiltersOptions = useCallback(async () => {
    try {
      const [brands, businessModels, itemStatuses, orderStatuses, partners, warehouse] = await Promise.all([
        api.productBrands_GetAll(),
        api.products_GetBusinessModels(),
        api.products_GetStatus(),
        api.submissions_GetAllStatuses(),
        api.partners_GetAll(),
        api.products_GetWarehouses()
      ]);

      const filters: IFilterOptions = {
        brands,
        businessModels,
        itemStatuses,
        orderStatuses,
        partners,
        warehouse: warehouse.map(({ id, name }) => ({ id, name }))
      } as IFilterOptions;

      setFilterOptions(filters);
    } catch {
      setFilterOptions(defaultFilterOptions);
      enqueueNotification({ title: t('common:errors.errorGetFilters') }, { variant: 'error' });
    }
  }, []);

  useEffect(() => {
    getItems();
    getFiltersOptions();
  }, []);

  useEffect(() => {
    getItems();
  }, [filters, page, pageSize, search, sortModel, statusFilter]);

  const handleFilters = useCallback((newFilters: IFilterValue) => setFilters(newFilters), []);

  const handlePage = useCallback((newPage: number) => setPage(newPage), []);

  const handlePageSize = useCallback((newPageSize: number) => setPageSize(newPageSize), []);

  const handleSearch = useCallback((newSearch: string) => setSearch(newSearch), []);

  const handleSetSortModel = useCallback((sortModel: GridSortModel) => setSortModel(sortModel), []);

  const handleStatusFilter = useCallback((_, newStatusFilter) => setStatusFilter(newStatusFilter), []);

  const handleResetUploadSubmissions = useCallback(() => {
    setIsSubmissionValidated(false);
    setUploadValidationMessages(undefined);
  }, [setIsSubmissionValidated, setUploadValidationMessages]);

  const handleSubmissionMessages = useCallback(
    ({ isValid, messages }: UploadSubmissionsFileResponse) => {
      if (isValid === undefined && !messages) {
        enqueueNotification(
          {
            title: t('authentication:list.errors.errorValidateList')
          },
          { variant: 'error' }
        );
      } else if (messages?.length) {
        const uploadValidationMessages: IUploadValidationMessage = messages.reduce(
          (result, { description, row, severityId }) => ({
            ...result,
            [severityId as ESeverity]: [...result[severityId as ESeverity], `Row ${row}: ${description}`]
          }),
          {
            [ESeverity.Error]: [] as string[],
            [ESeverity.Warning]: [] as string[]
          }
        );

        setUploadValidationMessages(uploadValidationMessages);
      }

      setIsSubmissionValidated(isValid || false);
    },
    [enqueueNotification, setIsSubmissionValidated, setUploadValidationMessages, t]
  );

  const handleUploadResults = useCallback(
    async (file: File, onSuccess: () => void) => {
      setIsSubmissionUploading(true);

      try {
        const res = await api.submissions_UpsetSubmissionsFile({ data: file, fileName: file.name });

        handleSubmissionMessages(res);

        if (res.isValid) {
          onSuccess();
          enqueueNotification({ title: t('authentication:list.successUploadResult') }, { variant: 'success' });
          getItems();
        }
      } catch (error: unknown) {
        handleSubmissionMessages(error as UploadSubmissionsFileResponse);
      } finally {
        setIsSubmissionUploading(false);
      }
    },
    [api, enqueueNotification, getItems, handleSubmissionMessages, t]
  );

  const handleValidateUpload = useCallback(
    async (file: File) => {
      setIsSubmissionValidating(true);

      try {
        const res = await api.submissions_ValidateSubmissionsFile({
          data: file,
          fileName: file.name
        });

        handleSubmissionMessages(res);
      } catch (error: unknown) {
        handleSubmissionMessages(error as UploadSubmissionsFileResponse);
      } finally {
        setIsSubmissionValidating(false);
      }
    },
    [api, handleSubmissionMessages]
  );

  return (
    <AuthenticationListContext.Provider
      value={{
        countOptions,
        filterOptions,
        filters,
        handleResetUploadSubmissions,
        handleUploadResults,
        handleValidateUpload,
        isLoading,
        isSubmissionUploading,
        isSubmissionValidated,
        isSubmissionValidating,
        items,
        page,
        pageSize,
        search,
        setFilters: handleFilters,
        setPage: handlePage,
        setPageSize: handlePageSize,
        setSearch: handleSearch,
        setSortModel: handleSetSortModel,
        setStatusFilter: handleStatusFilter,
        sortModel,
        statusFilter,
        totalItemsCount,
        uploadValidationMessages
      }}
    >
      {children}
    </AuthenticationListContext.Provider>
  );
};

const AuthenticationListConsumer = AuthenticationListContext.Consumer;

export { AuthenticationListConsumer, AuthenticationListProvider };

export default AuthenticationListContext;
