import { InboundFlowGetModel2 } from '@inbound/api';
import { UseFormMethods } from 'react-hook-form';
import * as yup from 'yup';

import {
  EDamage,
  EDamageLocation,
  EDamageSeverityLevel,
  EInboundFlow,
  EReturnToSupplierReason,
  EWarehouseName,
  ExteriorDamageLocationKeys,
  GenericDamageKeys,
  GenericDamageSmellKeys,
  HardwareDamageKeys,
  HardwareDamageLocationKeys,
  IInboundFlow,
  InteriorDamageLocationKeys,
  InteriorExteriorDamageOtherDamagesKeys,
  InteriorExteriorDamageStainedKeys,
  LocationKeys
} from 'models/warehouse';

const ENABLE_RETURN_TO_SUPPLIER_NEW = process.env.REACT_APP_ENABLE_RETURN_TO_SUPPLIER_NEW === 'true';

export interface IDamage {
  isAdditional: boolean;
  isChecked: boolean;
  photoUrl: string | null;
  previousSeverityId?: EDamageSeverityLevel | null;
  severityId?: EDamageSeverityLevel | null;
}

interface IItemDamage extends Omit<IDamage, 'isChecked'> {
  id: EDamage;
}

interface IItemDamages {
  damages: IItemDamage[];
  locationId: EDamageLocation;
}

export interface IInteriorExteriorDamages {
  otherDamages: {
    [key in InteriorExteriorDamageOtherDamagesKeys]: IDamage;
  };
  stained: {
    [key in InteriorExteriorDamageStainedKeys]: IDamage;
  };
}

type TQualityCheckExteriorDamageInputs = {
  [key in ExteriorDamageLocationKeys]: IInteriorExteriorDamages;
};

type TQualityCheckInteriorDamageInputs = {
  [key in InteriorDamageLocationKeys]: IInteriorExteriorDamages;
};

export type THardwareDamages = {
  [key in HardwareDamageKeys]: IDamage;
};

export type TGenericDamages = {
  [key in GenericDamageSmellKeys]: IDamage;
};

type TQualityCheckHardwareDamageInputs = {
  [key in HardwareDamageLocationKeys]: THardwareDamages;
};

type TQualityCheckGenericDamageInput = {
  [key in GenericDamageKeys]: TGenericDamages;
};

interface IOtherQualityCheckInputs {
  inboundFlow?: Readonly<InboundFlowGetModel2>;
  itemId: Readonly<string>;
  itemProcessId: Readonly<string>;
}

export interface IQualityCheckFormInputs
  extends TQualityCheckExteriorDamageInputs,
    TQualityCheckInteriorDamageInputs,
    TQualityCheckHardwareDamageInputs,
    TQualityCheckGenericDamageInput,
    IOtherQualityCheckInputs {
  hasPassedQualityCheck: boolean | undefined;
  // TODO: Remove once New QC Return to Supplier released
  isReturnToSupplier: boolean;
  // TODO: Remove once New QC Return to Supplier released
  returnToSupplierReasons: number[];
  supplierComments: Readonly<string>;
  supplierReturnReason?: number;
}

export const defaultDamage = {
  isAdditional: true,
  isChecked: false,
  photoUrl: null,
  previousSeverityId: null,
  severityId: null
};

const defaultOtherDamages = {
  cracked: defaultDamage,
  creased: defaultDamage,
  cutStitching: defaultDamage,
  discolored: defaultDamage,
  looseStitching: defaultDamage,
  outOfShape: defaultDamage,
  peeling: defaultDamage,
  personalized: defaultDamage,
  repaired: defaultDamage,
  ripped: defaultDamage,
  scratched: defaultDamage,
  split: defaultDamage,
  sticky: defaultDamage,
  worn: defaultDamage
};

const defaultStainedDamages = {
  others: defaultDamage,
  penMark: defaultDamage,
  transferOfColor: defaultDamage,
  waterMark: defaultDamage
};

export const defaultGenericDamages = {
  cigarette: defaultDamage,
  musty: defaultDamage,
  others: defaultDamage,
  perfume: defaultDamage
};

export const defaultInteriorExteriorDamages = {
  otherDamages: defaultOtherDamages,
  stained: defaultStainedDamages
};

export const defaultHardwareDamages = {
  damaged: defaultDamage,
  missing: defaultDamage,
  rustyOrTarnished: defaultDamage,
  scratched: defaultDamage
};

const damageSchema = yup.object().shape({
  isAdditional: yup.boolean(),
  isChecked: yup.boolean(),
  previousSeverityId: yup.string().nullable(),
  severityId: yup.string().nullable()
});

const damagePhotoSchema = damageSchema.shape({
  photoUrl: yup
    .string()
    .nullable()
    .when(['isChecked', 'isAdditional', '$inboundFlow', 'previousSeverityId', 'severityId'], {
      is: (isChecked: boolean, isAdditional: boolean, inboundFlow: IInboundFlow) =>
        isAdditional &&
        isChecked &&
        [
          EInboundFlow.ClientReturn,
          EInboundFlow.ConsignmentReturn,
          EInboundFlow.Hybrid,
          EInboundFlow.TransferProcessed
        ].includes(inboundFlow?.id),
      then: yup.string().required()
    })
});

const genericDamagesSchema = yup
  .object()
  .shape({
    cigarette: damageSchema,
    musty: damageSchema,
    others: damageSchema,
    perfume: damageSchema
  })
  .notRequired();

const interiorExteriorDamagesSchema = yup
  .object()
  .shape({
    otherDamages: yup
      .object()
      .shape({
        cracked: damagePhotoSchema,
        discolored: damagePhotoSchema,
        looseStitching: damagePhotoSchema,
        outOfShape: damagePhotoSchema,
        peeling: damagePhotoSchema,
        ripped: damagePhotoSchema,
        scratched: damagePhotoSchema,
        sticky: damagePhotoSchema,
        worn: damagePhotoSchema
      })
      .notRequired(),
    stained: yup
      .object()
      .shape({
        others: damagePhotoSchema,
        penMark: damagePhotoSchema,
        transferOfColor: damagePhotoSchema,
        waterMark: damagePhotoSchema
      })
      .notRequired()
  })
  .notRequired();

const hardwareDamagesSchema = yup
  .object()
  .shape({
    damaged: damagePhotoSchema,
    missing: damagePhotoSchema,
    rusty: damagePhotoSchema,
    scratched: damagePhotoSchema,
    tarnished: damagePhotoSchema
  })
  .notRequired();

export const qualityCheckSchema = (itemWarehouse: EWarehouseName) =>
  yup.object().shape({
    back: interiorExteriorDamagesSchema,
    bottom: interiorExteriorDamagesSchema,
    buckle: hardwareDamagesSchema,
    corners: interiorExteriorDamagesSchema,
    description: yup.string().notRequired(),
    front: interiorExteriorDamagesSchema,
    handle: interiorExteriorDamagesSchema,
    hasPassedQualityCheck: yup
      .boolean()
      .nullable()
      .when(['isReturnToSupplier', 'returnToSupplierReasons', 'supplierReturnReason'], {
        is: (
          isReturnToSupplier: boolean,
          returnToSupplierReasons: EReturnToSupplierReason[],
          supplierReturnReason?: number
        ) => {
          const isJapanWarehouse = itemWarehouse === EWarehouseName.Japan;

          return ENABLE_RETURN_TO_SUPPLIER_NEW
            ? supplierReturnReason !== undefined && isJapanWarehouse
            : (!isJapanWarehouse &&
                !isReturnToSupplier &&
                !returnToSupplierReasons.includes(EReturnToSupplierReason.AuthenticationFailed)) ||
                (isJapanWarehouse &&
                  isReturnToSupplier &&
                  returnToSupplierReasons.includes(EReturnToSupplierReason.QualityControlFailed));
        },
        then: yup.boolean().required()
      }),
    interiorPocket: interiorExteriorDamagesSchema,
    key: hardwareDamagesSchema,
    lining: interiorExteriorDamagesSchema,
    lock: hardwareDamagesSchema,
    metalAttachment: hardwareDamagesSchema,
    padlock: hardwareDamagesSchema,
    pocket: interiorExteriorDamagesSchema,
    ...(ENABLE_RETURN_TO_SUPPLIER_NEW
      ? {}
      : {
          isReturnToSupplier: yup.boolean().nullable().notRequired(),
          returnToSupplierReasons: yup
            .array()
            .when('isReturnToSupplier', { is: true, then: yup.array().of(yup.number().required()).min(1).required() })
        }),

    screw: hardwareDamagesSchema,
    side: interiorExteriorDamagesSchema,
    smell: genericDamagesSchema,
    stud: hardwareDamagesSchema,
    supplierReturnReason: yup.number().nullable().notRequired(),
    zipper: hardwareDamagesSchema
  });

export const defaultQualityCheckValues: IQualityCheckFormInputs = {
  back: defaultInteriorExteriorDamages,
  bottom: defaultInteriorExteriorDamages,
  closure: defaultHardwareDamages,
  corners: defaultInteriorExteriorDamages,
  embellishment: defaultHardwareDamages,
  front: defaultInteriorExteriorDamages,
  handleOrStrap: defaultInteriorExteriorDamages,
  hasPassedQualityCheck: undefined,
  inboundFlow: {} as InboundFlowGetModel2,
  interiorPocket: defaultInteriorExteriorDamages,
  // TODO: Remove once New QC Return to Supplier released
  isReturnToSupplier: false,
  itemId: '',
  itemProcessId: '',
  key: defaultHardwareDamages,
  lining: defaultInteriorExteriorDamages,
  pocket: defaultInteriorExteriorDamages,
  practicalAttachment: defaultHardwareDamages,
  // TODO: Remove once New QC Return to Supplier released
  returnToSupplierReasons: [],
  side: defaultInteriorExteriorDamages,
  smell: defaultGenericDamages,
  supplierComments: '',
  supplierReturnReason: undefined,
  top: defaultInteriorExteriorDamages,
  zipper: defaultHardwareDamages
};

export const qualityCheckInitialize = (
  items: any,
  reset: UseFormMethods<IQualityCheckFormInputs>['reset'],
  otherDefaults: IOtherQualityCheckInputs
) => {
  const initializeDamages = (key: LocationKeys, location: EDamageLocation) => {
    switch (key) {
      case 'exterior':
      case 'interior':
        return {
          otherDamages: {
            cracked: filterDamagePerLocation(items.itemDamages, location, EDamage.Cracked),
            creased: filterDamagePerLocation(items.itemDamages, location, EDamage.Creased),
            cutStitching: filterDamagePerLocation(items.itemDamages, location, EDamage.CutStitching),
            discolored: filterDamagePerLocation(items.itemDamages, location, EDamage.Discolored),
            looseStitching: filterDamagePerLocation(items.itemDamages, location, EDamage.LooseStitching),
            outOfShape: filterDamagePerLocation(items.itemDamages, location, EDamage.OutOfShape),
            peeling: filterDamagePerLocation(items.itemDamages, location, EDamage.Peeling),
            personalized: filterDamagePerLocation(items.itemDamages, location, EDamage.Personalized),
            repaired: filterDamagePerLocation(items.itemDamages, location, EDamage.Repaired),
            ripped: filterDamagePerLocation(items.itemDamages, location, EDamage.Ripped),
            scratched: filterDamagePerLocation(items.itemDamages, location, EDamage.Scratched),
            split: filterDamagePerLocation(items.itemDamages, location, EDamage.Split),
            sticky: filterDamagePerLocation(items.itemDamages, location, EDamage.Sticky),
            worn: filterDamagePerLocation(items.itemDamages, location, EDamage.Worn)
          },
          stained: {
            others: filterDamagePerLocation(items.itemDamages, location, EDamage.OtherStain),
            penMark: filterDamagePerLocation(items.itemDamages, location, EDamage.PenMark),
            transferOfColor: filterDamagePerLocation(items.itemDamages, location, EDamage.TransferOfColor),
            waterMark: filterDamagePerLocation(items.itemDamages, location, EDamage.WaterMark)
          }
        };
      case 'generic':
        return {
          cigarette: filterDamagePerLocation(items.itemDamages, location, EDamage.Cigarette),
          musty: filterDamagePerLocation(items.itemDamages, location, EDamage.Musty),
          others: filterDamagePerLocation(items.itemDamages, location, EDamage.OtherSmell),
          perfume: filterDamagePerLocation(items.itemDamages, location, EDamage.Perfume)
        };
      case 'hardware':
        return {
          damaged: filterDamagePerLocation(items.itemDamages, location, EDamage.Damaged),
          missing: filterDamagePerLocation(items.itemDamages, location, EDamage.Missing),
          rustyOrTarnished: filterDamagePerLocation(items.itemDamages, location, EDamage.RustyOrTarnished),
          scratched: filterDamagePerLocation(items.itemDamages, location, EDamage.Scratched)
        };
    }
  };

  const formItems = {
    ...otherDefaults,
    back: initializeDamages('exterior', EDamageLocation.Back),
    bottom: initializeDamages('exterior', EDamageLocation.Bottom),
    closure: initializeDamages('hardware', EDamageLocation.Closure),
    corners: initializeDamages('exterior', EDamageLocation.Corners),
    embellishment: initializeDamages('hardware', EDamageLocation.Embellishment),
    front: initializeDamages('exterior', EDamageLocation.Front),
    handleOrStrap: initializeDamages('exterior', EDamageLocation.HandleOrStrap),
    hasPassedQualityCheck: items.hasPassedQualityCheck,
    interiorPocket: initializeDamages('exterior', EDamageLocation.InteriorPocket),
    ...(ENABLE_RETURN_TO_SUPPLIER_NEW
      ? {}
      : {
          // TODO: Remove once New QC Return to Supplier released
          isReturnToSupplier: items.isToReturnToSupplier,
          returnToSupplierReasons: items.returnToSupplierReasons
        }),
    key: initializeDamages('hardware', EDamageLocation.Key),
    lining: initializeDamages('interior', EDamageLocation.Lining),
    pocket: initializeDamages('exterior', EDamageLocation.Pocket),
    practicalAttachment: initializeDamages('hardware', EDamageLocation.PracticalAttachment),
    side: initializeDamages('exterior', EDamageLocation.Side),
    smell: initializeDamages('generic', EDamageLocation.Smell),
    supplierComments: items.supplierComments ? items.supplierComments : '',
    supplierReturnReason: items.wasAcceptedBySupplier || undefined,
    top: initializeDamages('exterior', EDamageLocation.Top),
    zipper: initializeDamages('hardware', EDamageLocation.Zipper)
  };

  reset(formItems, { isDirty: false });
};

const filterDamagePerLocation = (
  damageLocations: IItemDamages[],
  locationId: EDamageLocation,
  damageId: EDamage
): IDamage => {
  const damageLocation = damageLocations.find(({ locationId: currentLocationId }) => currentLocationId === locationId);

  if (!damageLocation) {
    return defaultDamage;
  }

  const damage = damageLocation.damages.find(({ id }) => id === damageId);

  return damage
    ? {
        isAdditional: damage.isAdditional,
        isChecked: true,
        photoUrl: damage.photoUrl,
        previousSeverityId: !damage.isAdditional ? damage.severityId : null,
        severityId: damage.severityId
      }
    : defaultDamage;
};
