import { EPhotoFrame } from '@inbound/api';

export const LOCAL_STORAGE_USER_WAREHOUSE = 'userWarehouse';
export const LOCAL_STORAGE_USER_MEASUREMENT_UNIT = 'measurementUnit';
export const LOCAL_STORAGE_USER_WAREHOUSE_WARNING_DATE = 'userWarehouseWarning';
export const USER_WAREHOUSE_SHOW_WARNING_HOURS = 78;
export const LOCAL_STORAGE_WAREHOUSE_FILTER = 'warehouseFilter';

export enum EStationAlert {
  ForOutbound = 'ForOutbound',
  ForPhotography = 'ForPhotography'
}

export type TStationAlertKeys = 'forOutbound' | 'forPhotography';

export interface IUploadFormat {
  fileId: string;
  fileSize: number;
  name: string;
  originalName: string;
  type: string;
}

export enum EWarehouseFilterId {
  Brand = 'brand',
  InboundFlow = 'inboundFlow',
  Suppliers = 'supplier'
}

export interface IItemDetails {
  brand: string;
  category: string;
  hasProfessionalPhotos: boolean;
  inboundFlow: IInboundFlow;
  isAtBBox: boolean;
  itemConfiguredStations: IItemConfigStation[];
  itemId: string;
  itemPhotos: string[];
  itemProcessId: string;
  name: string;
  sellerCode: string;
  serialNumber: string | null;
  sku: string;
  stationAlert: EStationAlert | null;
  style: string;
  warehouse: EWarehouseName | undefined;
}

export interface IItemConfigStation {
  isComplete?: boolean;
  order: number;
  station: string;
  stationId: number;
}

export enum EInclusion {
  None = 1,
  DustBag = 2,
  Box = 3,
  AuthenticityCard = 4,
  Padlock = 5,
  Key = 6,
  Pouch = 7,
  NameTag = 8,
  Charm = 9,
  Mirror = 10,
  ShoulderStrap = 11
}

export enum EDamage {
  // Interior/Exterior Stained
  WaterMark = 4,
  TransferOfColor = 5,
  PenMark = 6,
  OtherStain = 7,
  // Interior/Exterior Smells
  Cigarette = 10,
  Musty = 9,
  OtherSmell = 11,
  Perfume = 8,
  // Interior/Exterior Other Damages
  Cracked = 15,
  Creased = 27,
  CutStitching = 25,
  Discolored = 12,
  LooseStitching = 16,
  OutOfShape = 19,
  Peeling = 14,
  Personalized = 29,
  Repaired = 28,
  Ripped = 13,
  Split = 26,
  Sticky = 17,
  Worn = 18,
  // Hardware
  Damaged = 21,
  Missing = 24,
  RustyOrTarnished = 23,
  // Hardware/Interior/Exterior
  Scratched = 20
}

// TODO: Cleanup if needed
export enum EDamageLocation {
  // Exterior
  Back = 5,
  Bottom = 6,
  Corners = 9,
  Front = 4,
  HandleOrStrap = 7,
  Pocket = 8,
  Side = 10,
  Top = 21,

  // Generic
  Smell = 24,

  // Interior
  InteriorPocket = 12,
  Lining = 11,

  // Hardware
  Closure = 18,
  Embellishment = 22,
  Key = 16,
  PracticalAttachment = 23,
  Zipper = 13
}

export enum EMeasure {
  Depth = 1,
  HandleDrop = 7,
  Length = 5,
  ShoulderDrop = 6,
  Width = 4
}

export interface IColorType {
  englishName: string;
  id: string;
  isMainColor: boolean;
  name: string;
}

export interface IInclusion {
  icon?: JSX.Element | string;
  id: EInclusion;
  isMissing: boolean;
  key?: string;
  name?: string;
}

export interface IItemPhoto {
  id: string;
  order: string;
  url: string;
}

export interface IItemPhotos {
  label: string;
  photos: IItemPhoto[];
  type: string;
}

export interface IInclusionData {
  icon: string;
  key: string;
}

export type LocationKeys = 'exterior' | 'generic' | 'hardware' | 'interior';

export type ExteriorDamageLocationKeys =
  | 'back'
  | 'bottom'
  | 'corners'
  | 'front'
  | 'handleOrStrap'
  | 'pocket'
  | 'side'
  | 'top';

export type InteriorDamageLocationKeys = 'interiorPocket' | 'lining';

export type MeasurementUnit = 'imperial' | 'metric';

export type HardwareDamageLocationKeys = 'closure' | 'embellishment' | 'key' | 'practicalAttachment' | 'zipper';

export type DamageLocationKeys = HardwareDamageLocationKeys | ExteriorDamageLocationKeys | InteriorDamageLocationKeys;

export type HardwareDamageKeys = 'damaged' | 'missing' | 'rustyOrTarnished' | 'scratched';

export type InteriorExteriorDamageKeys = 'otherDamages' | 'stained';

export type GenericDamageKeys = 'smell';

export type InteriorExteriorDamageOtherDamagesKeys =
  | 'cracked'
  | 'creased'
  | 'cutStitching'
  | 'discolored'
  | 'looseStitching'
  | 'outOfShape'
  | 'peeling'
  | 'personalized'
  | 'repaired'
  | 'ripped'
  | 'scratched'
  | 'split'
  | 'sticky'
  | 'worn';

export type GenericDamageSmellKeys = 'cigarette' | 'musty' | 'others' | 'perfume';

export type InteriorExteriorDamageSmellKeys = 'cigarette' | 'musty' | 'others' | 'perfume';

export type InteriorExteriorDamageStainedKeys = 'others' | 'penMark' | 'transferOfColor' | 'waterMark';

export type DamageCountKeys = 'isChecked' | 'hasPhotos';

export type TDamageCounts = {
  [key in DamageCountKeys]: number;
};

export type TExteriorDamageLocationCounts = {
  [key in ExteriorDamageLocationKeys]: TDamageCounts;
};

export type TInteriorDamageLocationCounts = {
  [key in InteriorDamageLocationKeys]: TDamageCounts;
};

export type THardwareDamageLocationCounts = {
  [key in HardwareDamageLocationKeys]: TDamageCounts;
};

export type TDamageLocationCounts =
  | TExteriorDamageLocationCounts
  | TInteriorDamageLocationCounts
  | THardwareDamageLocationCounts;

export enum EInboundFlow {
  ClientReturn = 'ClientReturn',
  ConsignmentReturn = 'ConsignmentReturn',
  Hybrid = 'Hybrid',
  Regular = 'Regular',
  TransferNotProcessed = 'TransferNotProcessed',
  TransferProcessed = 'TransferProcessed',
  VLG = 'VLG'
}

export type TPhotoFrameNameKeys = keyof typeof EPhotoFrame | 'Additional';

/**
 * @description Derived from the keys of TPhotoFrameNameKeys
 */
export type TPhotoAuthFrameLabelKeys =
  | 'additional'
  | 'back'
  | 'brandStamp'
  | 'buckleBack'
  | 'buckleDetail'
  | 'buttons'
  | 'caseEngraving'
  | 'clasp'
  | 'crown'
  | 'engravings'
  | 'front'
  | 'frontExterior'
  | 'fold'
  | 'hallmark'
  | 'hardware'
  | 'internalMechanismPhoto'
  | 'lensEngraving'
  | 'logo'
  | 'madeIn'
  | 'metalPurityMarking'
  | 'pendant'
  | 'pin'
  | 'qrTagHoloSticker'
  | 'rolledEdges'
  | 'serialNumber'
  | 'tags'
  | 'underTheButtons'
  | 'watchFace'
  | 'zipper'
  | 'zipperNose'
  | 'zipperTop'
  | 'zipperUnderside';

export interface IUploadedPhoto {
  fileId: string;
  uploadDate?: Date;
  url: string;
}

export interface IAuthUploadedPhoto extends IUploadedPhoto {
  photoFrameId: EPhotoFrame;
}

export enum EDnDPhotoAuth {
  ForAuthentication = 'ForAuthentication',
  ForUpload = 'ForUpload'
}

/**
 * @description Derived from the keys of EItemExtraFieldsKey
 */
export type TMeasurementsKeys =
  | 'beltLength'
  | 'beltSize'
  | 'buckleLength'
  | 'buckleWidth'
  | 'carat'
  | 'caseSize'
  | 'circumference'
  | 'depth'
  | 'diameter'
  | 'handleDrop'
  | 'height'
  | 'length'
  | 'ringSize'
  | 'shoeSize'
  | 'shoulderDrop'
  | 'strapLength'
  | 'thickness'
  | 'weight'
  | 'width';

export interface IInboundFlow {
  id: EInboundFlow;
  name: string;
}

export enum EDamageSeverityLevel {
  High = 'High',
  Low = 'Low',
  Medium = 'Medium'
}

export enum EWarehouseAction {
  EDIT = 'edit',
  ON_HOLD = 'on-hold',
  VIEW = 'view'
}

export enum EWarehouseName {
  Japan = 'GINZA PARIS',
  USA = 'AMB @CODED'
}

// TODO: Remove once New QC Return to Supplier released
export enum EReturnToSupplierReason {
  AuthenticationFailed = 1,
  QualityControlFailed = 2
}
