import React, { useCallback, useMemo, useState } from 'react';

import { ESubmissionStatus } from '@inbound/api';
import { LayoutLyssa } from '@lux-ds/body-layouts';
import { LayoutMervin } from '@lux-ds/content-layouts';
import DataGrid, {
  DataGridDoubleCell,
  GridColumns,
  GridRenderCellParams,
  GridValueFormatterParams,
  IDataGridToolbarProps
} from '@lux-ds/data-grid';
import { ButtonRadio, RadioGroup } from '@lux-ds/radio';
import StatusIndicator from '@lux-ds/status-indicator';
import CloudUpload from '@mui/icons-material/CloudUpload';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import UploadResultsModal from 'components/Authentication/UploadResultsModal/UploadResultsModal';
import { authStatusColor } from 'constants/StatusColor';
import { EColumnFilterTypes } from 'models/component/luxDataTableInterface';
import BaseConfiguration from 'setup/BaseConfiguration';

import useAuthenticationListContext from './hooks/useAuthenticationListContext';

type ICountLabelKeys = 'notSubmitted' | 'submitted' | 'withIssues' | 'completed' | 'inconclusive';

const AuthenticationList: React.FC = () => {
  const {
    countOptions,
    filterOptions,
    filters,
    isLoading,
    items,
    page,
    pageSize,
    search,
    setFilters,
    setPage,
    setPageSize,
    setSearch,
    setSortModel,
    setStatusFilter,
    sortModel,
    statusFilter,
    totalItemsCount
  } = useAuthenticationListContext();
  const { t } = useTranslation(['authentication', 'common']);

  const [openUploadResultsModal, setOpenUploadResultsModal] = useState<boolean>(false);

  const columns = useMemo(
    (): GridColumns => [
      {
        field: 'sku',
        flex: 1,
        headerName: t('common:sku')
      },

      { field: 'name', flex: 1, headerName: t('common:name') },
      { field: 'brand', flex: 1, headerName: t('common:brand') },
      {
        field: 'availableSinceUtc',
        flex: 1,
        headerName: t('authentication:list.availableSince'),
        renderCell: Object.assign(
          ({ row, value }: GridRenderCellParams) => (
            <DataGridDoubleCell
              subTitle={
                row.availableSinceUtcDaysOfDifference
                  ? `${row.availableSinceUtcDaysOfDifference} ${t('authentication:list.day')}`
                  : '-'
              }
              title={moment(value).format('YYYY/MM/DD LT')}
              type="row"
            />
          ),
          { displayName: 'AvailableSinceCell' }
        )
      },
      {
        field: 'statusUpdatedAtUtc',
        flex: 1,
        headerName: t('authentication:list.statusUpdate'),
        renderCell: Object.assign(
          ({ row, value }: GridRenderCellParams) => (
            <DataGridDoubleCell
              subTitle={
                row.statusUpdatedAtUtcDaysOfDifference
                  ? `${row.statusUpdatedAtUtcDaysOfDifference} ${t('authentication:list.day')}`
                  : '-'
              }
              title={value ? moment(value).format('YYYY/MM/DD LT') : '-'}
              type="row"
            />
          ),
          { displayName: 'StatusUpdateCell' }
        )
      },
      {
        field: 'partnerId',
        flex: 1,
        headerName: t('authentication:list.partner'),
        renderCell: Object.assign(({ row }: GridRenderCellParams) => (row.partnerId ? row.partner : '-'), {
          displayName: 'PartnerCell'
        })
      },
      {
        field: 'warehouse',
        flex: 1,
        headerName: t('common:warehouse'),
        valueFormatter: ({ value }: GridValueFormatterParams) => value || '-'
      },
      {
        field: 'statusId',
        flex: 1,
        headerName: t('common:status'),
        renderCell: Object.assign(
          ({ row }: GridRenderCellParams) => (
            <StatusIndicator label={row.status} color={authStatusColor[row.statusId as ESubmissionStatus]} />
          ),
          {
            displayName: 'StatusCell'
          }
        )
      }
    ],
    [t]
  );

  const toolbar = useMemo(
    (): IDataGridToolbarProps => ({
      actions: [
        ...(process.env.REACT_APP_ENABLE_UPLOAD_AUTHENTICATION_SUBMISSIONS === 'true'
          ? [
              {
                icon: <CloudUpload />,
                label: t('authentication:list.uploadResults'),
                onClick: () => setOpenUploadResultsModal(true)
              }
            ]
          : [])
      ],
      filter: {
        collapseMountOnEnter: true,
        collapseUnmountOnExit: true,
        filters: [
          {
            id: 'warehouseIds',
            label: t('common:warehouse'),
            options: filterOptions.warehouse,
            type: EColumnFilterTypes.MultipleSelect
          },
          {
            id: 'brandIds',
            label: t('common:brand'),
            options: filterOptions.brands,
            type: EColumnFilterTypes.MultipleSelect
          },
          {
            id: 'partnerIds',
            label: t('authentication:list.partner'),
            options: filterOptions.partners,
            type: EColumnFilterTypes.MultipleSelect
          },
          {
            id: 'businessModels',
            label: t('common:businessModel'),
            options: filterOptions.businessModels,
            type: EColumnFilterTypes.MultipleSelect
          },
          {
            id: 'orderStatusIds',
            label: t('authentication:list.orderStatus'),
            options: filterOptions.orderStatuses,
            type: EColumnFilterTypes.MultipleSelect
          },
          {
            id: 'itemStatusIds',
            label: t('authentication:list.itemStatus'),
            options: filterOptions.itemStatuses,
            type: EColumnFilterTypes.MultipleSelect
          },
          {
            id: 'statusUpdateRange',
            label: t('authentication:list.statusUpdate'),
            type: EColumnFilterTypes.DateRange
          },
          {
            id: 'availableSinceRange',
            label: t('authentication:list.availableSince'),
            type: EColumnFilterTypes.DateRange
          }
        ],
        onChange: setFilters,
        value: filters
      },
      search: {
        defaultValue: search,
        onSearch: setSearch,
        placeholder: t('common:dataTable.search')
      }
    }),
    [filterOptions, filters, search, t]
  );

  const getCountLabel = useCallback(
    (countKey: ESubmissionStatus | string) => {
      if (countKey) {
        return t(`authentication:status.${countKey as ICountLabelKeys}`);
      } else {
        return t('common:dataTable.allItems');
      }
    },
    [countOptions, t]
  );

  return (
    <BaseConfiguration>
      <LayoutLyssa>
        <RadioGroup onChange={setStatusFilter} value={statusFilter}>
          <LayoutMervin gap="xs">
            {countOptions.map(({ count, key }) => (
              <ButtonRadio background="secondary" key={key} label={getCountLabel(key)} number={count} value={key} />
            ))}
          </LayoutMervin>
        </RadioGroup>
        <DataGrid
          columns={columns}
          getRowId={({ id }) => id}
          headerHeight="s"
          loading={isLoading}
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          onSortModelChange={setSortModel}
          page={page}
          pageSize={pageSize}
          paginationMode="server"
          rowCount={totalItemsCount}
          rowHeight="m"
          rows={items}
          sortModel={sortModel}
          sortingMode="server"
          toolbar={toolbar}
        />
      </LayoutLyssa>
      {openUploadResultsModal && <UploadResultsModal onClose={() => setOpenUploadResultsModal(false)} />}
    </BaseConfiguration>
  );
};

export default AuthenticationList;
