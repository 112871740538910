import { EIssueStatus } from '@inbound/api';
import { LuxTheme } from '@lux-ds/theming';

export enum EItemIssuesFilterId {
  Brand = 'brandIds',
  BusinessModel = 'businessModelIds',
  Category = 'categoryIds',
  DateRange = 'dateRange',
  Status = 'statusIds',
  Supplier = 'supplierIds',
  Warehouse = 'warehouseIds'
}

export type TItemIssuesStatusKeys = 'toDo' | 'inProgress' | 'sentToWarehouse' | 'resolvedByWarehouse' | 'resolved';

export type TItemIssuesTypeKeys =
  | 'authentication'
  | 'checkItemInfo'
  | 'photoEditing'
  | 'photography'
  | 'qualityControl'
  | 'supplierReturn';

export const issueStatuses = Object.entries(EIssueStatus).filter(
  ([key]) => isNaN(Number(key)) && key !== EIssueStatus[EIssueStatus.ResolvedByWarehouse]
);

export const issueStatusesAll = Object.entries(EIssueStatus).filter(([key]) => isNaN(Number(key)));

export const itemIssueColor: Record<EIssueStatus, string> = {
  [EIssueStatus.InProgress]: LuxTheme.palette.olayinka.main,
  [EIssueStatus.Resolved]: LuxTheme.palette.jindra.main,
  [EIssueStatus.ResolvedByWarehouse]: LuxTheme.palette.citlali.main,
  [EIssueStatus.SentToWarehouse]: LuxTheme.palette.citlali.main,
  [EIssueStatus.ToDo]: LuxTheme.palette.purdie.main
};
