import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

import { css, styled } from 'utils/styles';

export const StyledTurnedInIcon = styled(AssignmentTurnedInIcon)(
  ({ theme }) => css`
    color: ${theme.palette.primary.shade[40]};
    height: 12px;
    width: 12px;
  `
);
