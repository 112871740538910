import React, { useCallback, useMemo } from 'react';

import { EIssueStatus, EIssueType, IssueModel } from '@inbound/api';
import { LayoutLyssa } from '@lux-ds/body-layouts';
import { LayoutHerodias, LayoutJay, LayoutMervin } from '@lux-ds/content-layouts';
import DataGrid, {
  GridColumns,
  GridRenderCellParams,
  GridValueFormatterParams,
  IDataGridToolbarProps,
  IMultipleSelectValue
} from '@lux-ds/data-grid';
import { ButtonRadio, RadioGroup } from '@lux-ds/radio';
import StatusIndicator from '@lux-ds/status-indicator';
import Tooltip from '@lux-ds/tooltip';
import Typography from '@lux-ds/typography';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { EColumnFilterTypes } from 'models/component/luxDataTableInterface';
import camelize from 'utils/camelize';

import CustomStatusIndicator from './components/CustomStatusIndicator/CustomStatusIndicator';
import IssueHistoryModal from './components/IssueHistoryModal/IssueHistoryModal';
import {
  EItemIssuesFilterId,
  TItemIssuesStatusKeys,
  TItemIssuesTypeKeys,
  issueStatusesAll,
  itemIssueColor
} from './constants';
import { ItemIssueDetailsProvider } from './contexts/ItemIssueDetailsContext';
import useItemIssuesContext from './hooks/useItemIssuesContext';
import { StyledTurnedInIcon } from './ItemIssue.style';

const ItemIssues: React.FC = () => {
  const { t } = useTranslation(['common', 'itemIssues']);
  const {
    filterOptions,
    filters,
    filterStatuses,
    isIssueHistoryModalOpen,
    isLoading,
    items,
    page,
    pageSize,
    quickFilter,
    search,
    setFilters,
    setIssueHistoryModalOpen,
    setPage,
    setPageSize,
    setQuickFilter,
    setSearch,
    setSortModel,
    sortModel,
    totalItemsCount
  } = useItemIssuesContext();

  const columns = useMemo(
    (): GridColumns => [
      {
        field: 'sku',
        headerName: t('common:sku'),
        width: 215
      },
      {
        field: 'warehouse',
        headerName: t('common:warehouse'),
        width: 177
      },
      {
        field: 'createdAtUtc',
        headerName: t('itemIssues:date'),
        valueFormatter: ({ value }: GridValueFormatterParams) => moment(value).format('DD MMMM YYYY, hh:mm A'),
        width: 262
      },
      {
        field: 'numberOfDaysSinceItsWasCreated',
        headerName: t('itemIssues:days'),
        renderCell: Object.assign(
          ({ row }: GridRenderCellParams) => {
            const issues = [...row.issues];
            const value = row.numberOfDaysSinceItsWasCreated;

            return (
              <Tooltip
                title={
                  <LayoutJay gap="xs" sx={{ padding: theme => theme.space.xs }}>
                    {issues
                      .sort((a: IssueModel, b: IssueModel) => (a.typeId as number) - (b.typeId as number))
                      .map(({ numberOfDaysSinceItsWasCreated, typeId }: IssueModel) => (
                        <Typography color="white" key={typeId} variant="bodyTextSmall">
                          {`${t(
                            `itemIssues:issueTypes.${camelize(EIssueType[typeId as EIssueType]) as TItemIssuesTypeKeys}`
                          )}: ${t(
                            (numberOfDaysSinceItsWasCreated as number) > 1
                              ? 'itemIssues:daysValue'
                              : 'itemIssues:dayValue',
                            { value: numberOfDaysSinceItsWasCreated }
                          )}`}
                        </Typography>
                      ))}
                  </LayoutJay>
                }
              >
                <div>
                  <Typography variant="bodyTextSmall">
                    {t(value > 1 ? 'itemIssues:daysValue' : 'itemIssues:dayValue', { value })}
                  </Typography>
                </div>
              </Tooltip>
            );
          },
          {
            displayName: 'issuesType'
          }
        ),
        valueFormatter: ({ value }: GridValueFormatterParams) =>
          t(value > 1 ? 'itemIssues:daysValue' : 'itemIssues:dayValue', { value }),
        width: 106
      },
      {
        field: 'issues',
        flex: 1,
        headerName: t('itemIssues:issuesType'),
        renderCell: Object.assign(
          ({ row }: GridRenderCellParams) => (
            <LayoutHerodias>
              {row.issues.map((issue: IssueModel, index: number) => (
                <CustomStatusIndicator
                  color={itemIssueColor[issue.statusId as EIssueStatus]}
                  filled
                  key={index}
                  label={t(
                    `itemIssues:issueTypes.${camelize(EIssueType[issue.typeId as EIssueType]) as TItemIssuesTypeKeys}`
                  )}
                  rounded
                  endIcon={
                    issue.statusId === EIssueStatus.ResolvedByWarehouse ? (
                      <Tooltip title={t('itemIssues:submittedFromWarehouse')}>
                        <StyledTurnedInIcon />
                      </Tooltip>
                    ) : undefined
                  }
                />
              ))}
            </LayoutHerodias>
          ),
          {
            displayName: 'issuesType'
          }
        )
      }
    ],
    [t]
  );

  const toolbar = useMemo(
    (): IDataGridToolbarProps => ({
      filter: {
        collapseMountOnEnter: true,
        collapseUnmountOnExit: true,
        filters: [
          {
            id: EItemIssuesFilterId.Status,
            label: t('itemIssues:issueStatus'),
            options: filterOptions.statuses as IMultipleSelectValue[],
            type: EColumnFilterTypes.MultipleSelect
          },
          {
            id: EItemIssuesFilterId.DateRange,
            label: t('itemIssues:date'),
            type: EColumnFilterTypes.DateRange
          },
          {
            id: EItemIssuesFilterId.Warehouse,
            label: t('common:warehouse'),
            options: filterOptions.warehouses as IMultipleSelectValue[],
            type: EColumnFilterTypes.MultipleSelect
          },
          {
            id: EItemIssuesFilterId.Brand,
            label: t('common:brand'),
            options: filterOptions.brands as IMultipleSelectValue[],
            type: EColumnFilterTypes.MultipleSelect
          },
          {
            id: EItemIssuesFilterId.Category,
            label: t('common:category'),
            options: filterOptions.categories as IMultipleSelectValue[],
            type: EColumnFilterTypes.MultipleSelect
          },
          {
            id: EItemIssuesFilterId.BusinessModel,
            label: t('common:businessModel'),
            options: filterOptions.businessModels as IMultipleSelectValue[],
            type: EColumnFilterTypes.MultipleSelect
          },
          {
            id: EItemIssuesFilterId.Supplier,
            label: t('common:supplier'),
            options: filterOptions.suppliers as IMultipleSelectValue[],
            type: EColumnFilterTypes.MultipleSelect
          }
        ],
        onChange: setFilters,
        value: filters
      },
      search: {
        defaultValue: search,
        onSearch: setSearch,
        placeholder: t('common:searchBySKU')
      }
    }),
    [filterOptions, filters, search, setFilters, setSearch, t]
  );

  const handleRowClick = useCallback(({ row }) => setIssueHistoryModalOpen(row.itemId), [setIssueHistoryModalOpen]);

  return (
    <>
      <LayoutLyssa>
        <LayoutHerodias sx={{ justifyContent: 'space-between' }}>
          <RadioGroup onChange={setQuickFilter} value={quickFilter}>
            <LayoutMervin gap="xs">
              {filterStatuses.map(({ count, id, label }) => (
                <ButtonRadio key={id} background="secondary" label={label} number={count} value={id} />
              ))}
            </LayoutMervin>
          </RadioGroup>
          <LayoutHerodias align="right" sx={{ flexWrap: 'wrap', width: 'unset' }}>
            {issueStatusesAll.map(([key, value]) =>
              key === EIssueStatus[EIssueStatus.ResolvedByWarehouse] ? (
                <LayoutHerodias gap="xs" key={key} sx={{ height: 'unset', width: 'max-content' }}>
                  <StyledTurnedInIcon />
                  <Typography variant="bodyTextSmall">{t(`itemIssues:submittedFromWarehouse`)}</Typography>
                </LayoutHerodias>
              ) : (
                <StatusIndicator
                  color={itemIssueColor[Number(value) as EIssueStatus]}
                  key={key}
                  label={t(`itemIssues:issueStatuses.${camelize(key) as TItemIssuesStatusKeys}`)}
                />
              )
            )}
          </LayoutHerodias>
        </LayoutHerodias>
        <DataGrid
          columns={columns}
          getRowId={({ itemId }) => itemId}
          headerHeight="s"
          loading={isLoading}
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          onRowClick={handleRowClick}
          onSortModelChange={setSortModel}
          page={page}
          pageSize={pageSize}
          paginationMode="server"
          rowCount={totalItemsCount}
          rowHeight="s"
          rows={items}
          sortModel={sortModel}
          sortingMode="server"
          toolbar={toolbar}
        />
      </LayoutLyssa>
      {isIssueHistoryModalOpen && (
        <ItemIssueDetailsProvider>
          <IssueHistoryModal />
        </ItemIssueDetailsProvider>
      )}
    </>
  );
};

export default ItemIssues;
