import { LayoutHerodias, LayoutJay } from '@lux-ds/content-layouts';
import { styled } from '@lux-ds/theming/dist/cjs/styles';
import Typography from '@lux-ds/typography';
import CloudUpload from '@mui/icons-material/CloudUpload';

export const StyledDropzone = styled(LayoutJay)(({ theme }) => ({
  alignItems: 'center',
  background: theme.palette.primary.shade[90],
  border: `1px dashed ${theme.palette.primary.shade[70]}`,
  borderRadius: theme.space['xxs'],
  flexDirection: 'column',
  padding: theme.space['m']
}));

export const StyledHerodias = styled(LayoutHerodias)(() => ({
  flex: 1,
  justifyContent: 'space-between'
}));

export const StyledIcon = styled(CloudUpload)(({ theme }) => ({
  color: theme.palette.primary.shade[40],
  height: theme.space['xl'],
  width: theme.space['xl']
}));

export const StyledSubtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.shade[40],
  textAlign: 'center'
}));
