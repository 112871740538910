import React, { useCallback } from 'react';

import { EIssueStatus, EIssueType } from '@inbound/api';
import { LayoutAriel, LayoutHerodias, LayoutJay, LayoutMervin } from '@lux-ds/content-layouts';
import IconButton from '@lux-ds/icon-button';
import Image from '@lux-ds/image';
import { ButtonRadio, RadioGroup } from '@lux-ds/radio';
import ReadOnlyField from '@lux-ds/read-only-field';
import Typography from '@lux-ds/typography';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

import { TItemIssuesTypeKeys, itemIssueColor } from 'pages/ItemIssues/constants';
import useItemIssueDetailsContext from 'pages/ItemIssues/hooks/useItemIssueDetailsContext';
import useItemIssuesContext from 'pages/ItemIssues/hooks/useItemIssuesContext';
import camelize from 'utils/camelize';

import ActionSection from './sections/ActionSection';
import HistorySection from './sections/HistorySection';

import { StyledDialog, StyledFooter } from './IssueHistoryModal.styles';

const IssueHistoryModal: React.FC = () => {
  const { t } = useTranslation(['common', 'itemIssues']);
  const { setIssueHistoryModalClose } = useItemIssuesContext();
  const { itemIssueDetails, selectedTab, setSelectedTab } = useItemIssueDetailsContext();

  const renderTabs = useCallback(
    () =>
      itemIssueDetails.issues?.map(({ id, statusId, typeId }) => (
        <ButtonRadio
          background="secondary"
          colorIndicator={itemIssueColor[statusId as EIssueStatus]}
          key={id}
          label={t(`itemIssues:issueTypes.${camelize(EIssueType[typeId as EIssueType]) as TItemIssuesTypeKeys}`)}
          value={id as string}
        />
      )),
    [itemIssueDetails, t]
  );

  const handleChangeTab = useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, value: string) => setSelectedTab(value),
    [setSelectedTab]
  );

  const handleClose = useCallback(() => setIssueHistoryModalClose(), [setIssueHistoryModalClose]);

  return (
    <StyledDialog onClose={handleClose} open>
      <LayoutJay gap="m" paddingBottom="2xl">
        <LayoutHerodias gap="2xl" sx={{ alignItems: 'flex-start' }}>
          <LayoutHerodias gap="2xl">
            <Image border height={100} src={itemIssueDetails.thumbnail || ''} width={100} />
            <LayoutJay sx={{ width: '100%' }}>
              <Typography variant="h6" weight="semibold">
                {`${t('common:sku')} ${itemIssueDetails.sku}`}
              </Typography>
              <LayoutAriel gridTemplateColumns="repeat(4, 1fr)">
                <ReadOnlyField label={t('common:category')}>{itemIssueDetails.category}</ReadOnlyField>
                <ReadOnlyField label={t('common:brand')}>{itemIssueDetails.brand}</ReadOnlyField>
                <ReadOnlyField label={t('common:itemBusinessModel')}>{itemIssueDetails.businessModel}</ReadOnlyField>
                <ReadOnlyField label={t('common:supplier')}>{itemIssueDetails.supplier}</ReadOnlyField>
              </LayoutAriel>
            </LayoutJay>
          </LayoutHerodias>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </LayoutHerodias>
        <RadioGroup onChange={handleChangeTab} value={selectedTab}>
          <LayoutMervin gap="l">{renderTabs()}</LayoutMervin>
        </RadioGroup>
      </LayoutJay>
      <StyledFooter sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }}>
        <HistorySection />
        <ActionSection />
      </StyledFooter>
    </StyledDialog>
  );
};

export default IssueHistoryModal;
