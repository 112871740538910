/// <reference types="@lux-ds/theming/custom" />

import React from 'react';

import Tooltip from '@lux-ds/tooltip';

import { ColorIndicator, LabelTypography, StatusIndicatorContainer } from './styles/CustomStatusIndicator.style';

export interface ICustomStatusIndicatorProps {
  className?: string;
  color?: string;
  endIcon?: React.ReactNode;
  filled?: boolean;
  hint?: string;
  label: string;
  rounded?: boolean;
}

const CustomStatusIndicator: React.FC<ICustomStatusIndicatorProps> = ({
  className,
  color,
  endIcon,
  filled,
  hint,
  label,
  rounded
}) => (
  <Tooltip title={hint || ''}>
    <StatusIndicatorContainer $filled={!!filled} $rounded={!!rounded} className={className}>
      <ColorIndicator $color={color} />
      <LabelTypography variant="label">{label}</LabelTypography>
      {!!endIcon && endIcon}
    </StatusIndicatorContainer>
  </Tooltip>
);

export default CustomStatusIndicator;
