import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';

import { EIssueType, GetAllIssuesQueryResponse } from '@inbound/api';
import {
  GridSortModel,
  IDateRangeValue,
  IFilterValue,
  IMultipleSelectOptionIdName,
  IMultipleSelectOptionKeyValue,
  IValueRangeValue
} from '@lux-ds/data-grid';
import { useNotification } from '@lux-ds/notification';
import { useTranslation } from 'react-i18next';

import useApi from 'hooks/useApi';
import { IStatusFilterOption } from 'models/common';
import camelize from 'utils/camelize';

import { TItemIssuesStatusKeys, TItemIssuesTypeKeys, issueStatusesAll } from '../constants';

const filterDefault = {
  brandIds: [],
  businessModelIds: [],
  categoryIds: [],
  dateRange: {},
  statusIds: [],
  supplierIds: [],
  warehouseIds: []
};

interface IFilterOptions {
  brands: Partial<IMultipleSelectOptionIdName>[];
  businessModels: Partial<IMultipleSelectOptionKeyValue>[];
  categories: Partial<IMultipleSelectOptionIdName>[];
  statuses: Partial<IMultipleSelectOptionIdName>[];
  suppliers: Partial<IMultipleSelectOptionKeyValue>[];
  warehouses: Partial<IMultipleSelectOptionIdName>[];
}

interface IItemIssuesContext {
  filterOptions: IFilterOptions;
  filterStatuses: IStatusFilterOption[];
  filters: IFilterValue;
  isIssueHistoryModalOpen: boolean;
  isLoading: boolean;
  items: GetAllIssuesQueryResponse[];
  page: number;
  pageSize: number;
  quickFilter: string;
  search: string;
  selectedItemIssue: string;
  setFilters: (filters: IFilterValue) => void;
  setIssueHistoryModalClose: () => void;
  setIssueHistoryModalOpen: (itemId: string) => void;
  setPage: (newPage: number) => void;
  setPageSize: (newPageSize: number) => void;
  setQuickFilter: (event: React.SyntheticEvent<Element, Event>, quickFilter: string) => void;
  setSearch: (search: string) => void;
  setSortModel: (sortModel: GridSortModel) => void;
  sortModel: GridSortModel;
  totalItemsCount: number;
}

const ItemIssuesContext = createContext<IItemIssuesContext>({
  filterOptions: {
    brands: [],
    businessModels: [],
    categories: [],
    statuses: [],
    suppliers: [],
    warehouses: []
  },
  filterStatuses: [],
  filters: {},
  isIssueHistoryModalOpen: false,
  isLoading: false,
  items: [],
  page: 0,
  pageSize: 25,
  quickFilter: '',
  search: '',
  selectedItemIssue: '',
  setFilters: () => void 0,
  setIssueHistoryModalClose: () => void 0,
  setIssueHistoryModalOpen: () => void 0,
  setPage: () => void 0,
  setPageSize: () => void 0,
  setQuickFilter: () => void 0,
  setSearch: () => void 0,
  setSortModel: () => void 0,
  sortModel: [],
  totalItemsCount: 0
});

const ItemIssuesProvider: React.FC = ({ children }) => {
  const api = useApi();
  const { enqueueNotification } = useNotification();
  const { t } = useTranslation(['common', 'itemIssues']);

  const defaultFilterStatus = useMemo(
    (): IStatusFilterOption => ({
      count: 0,
      id: '',
      label: t('itemIssues:issueTypes.allIssues')
    }),
    [t]
  );

  const [filterOptions, setFilterOptions] = useState<IItemIssuesContext['filterOptions']>({
    brands: [],
    businessModels: [],
    categories: [],
    statuses: [],
    suppliers: [],
    warehouses: []
  });
  const [filterStatuses, setFilterStatuses] = useState<IItemIssuesContext['filterStatuses']>([defaultFilterStatus]);
  const [filters, setFilters] = useState<IItemIssuesContext['filters']>({});
  const [isLoading, setIsLoading] = useState<IItemIssuesContext['isLoading']>(false);
  const [items, setItems] = useState<IItemIssuesContext['items']>([]);
  const [page, setPage] = useState<IItemIssuesContext['page']>(0);
  const [pageSize, setPageSize] = useState<IItemIssuesContext['pageSize']>(25);
  const [quickFilter, setQuickFilter] = useState<IItemIssuesContext['quickFilter']>('');
  const [search, setSearch] = useState<IItemIssuesContext['search']>('');
  const [sortModel, setSortModel] = useState<IItemIssuesContext['sortModel']>([]);
  const [totalItemsCount, setTotalItemsCount] = useState<IItemIssuesContext['totalItemsCount']>(0);

  const [isIssueHistoryModalOpen, setIsIssueHistoryModalOpen] =
    useState<IItemIssuesContext['isIssueHistoryModalOpen']>(false);
  const [selectedItemIssue, setSelectedItemIssue] = useState<IItemIssuesContext['selectedItemIssue']>('');

  const [isFetchingFilters, setIsFetchingFilters] = useState<boolean>(true);

  const handleSetFilters = useCallback((filters: IFilterValue) => setFilters(filters), []);
  const handleSetPage = useCallback((newPage: number) => setPage(newPage), []);
  const handleSetPageSize = useCallback((newPageSize: number) => setPageSize(newPageSize), []);
  const handleSetQuickFilter = useCallback((_, quickFilter: string) => setQuickFilter(quickFilter), []);
  const handleSetSearch = useCallback((search: string) => setSearch(search), []);
  const handleSetSortModel = useCallback((sortModel: GridSortModel) => setSortModel(sortModel), []);

  const getFilterOptions = useCallback(async () => {
    try {
      const [brands, businessModels, categories, suppliers, warehouses] = await Promise.all([
        api.productBrands_GetAll(),
        api.products_GetBusinessModels(),
        api.categories_GetAll(),
        api.products_GetSuppliers(),
        api.warehouse_GetLuxclusifWarehouses()
      ]);

      const statuses = issueStatusesAll.map(([key, value]) => ({
        id: value,
        name: t(`itemIssues:issueStatuses.${camelize(key) as TItemIssuesStatusKeys}`)
      }));

      const filterOptions: IFilterOptions = {
        brands,
        businessModels,
        categories,
        statuses,
        suppliers,
        warehouses
      };

      setFilterOptions(filterOptions);
    } catch {
      enqueueNotification({ title: t('common:errors.errorGetFilters') }, { variant: 'error' });
    } finally {
      setIsFetchingFilters(false);
    }
  }, [api, enqueueNotification, t]);

  const getItems = useCallback(async () => {
    setIsLoading(true);

    try {
      const { brandIds, businessModelIds, categoryIds, dateRange, statusIds, supplierIds, warehouseIds } =
        Object.entries(filters).reduce(
          (
            result: Record<string, IDateRangeValue | IValueRangeValue | IMultipleSelectOptionIdName['id'][]>,
            [key, values]
          ) => ({
            ...result,
            [key]:
              (Array.isArray(values) ? values.map(value => ('id' in value ? value.id : value.key)) : values) || null
          }),
          filterDefault
        );

      const { field: orderBy, sort: orderDirection } = sortModel[0] || {};

      const result = await api.issues_GetAll(
        page,
        pageSize,
        search,
        orderBy || null,
        orderDirection || null,
        statusIds as number[],
        (dateRange as IDateRangeValue)?.startDate || null,
        (dateRange as IDateRangeValue)?.endDate || null,
        warehouseIds as string[],
        brandIds as string[],
        categoryIds as string[],
        businessModelIds as number[],
        supplierIds as string[],
        quickFilter as unknown as EIssueType
      );

      setItems(result.items || []);
      setTotalItemsCount(result.totalItems || 0);
    } catch {
      setItems([]);
      enqueueNotification({ title: t('itemIssues:errors.errorGetItemIssues') }, { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  }, [api, enqueueNotification, filters, page, pageSize, quickFilter, search, sortModel, t]);

  const getIssueCounts = useCallback(async () => {
    try {
      const result = await api.issues_GetCounts();

      const filterStatuses = Object.entries(result).map(([key, value]) => ({
        count: value,
        id: key !== 'allIssues' ? key : '',
        label: t(`itemIssues:issueTypes.${key as TItemIssuesTypeKeys}`)
      }));

      setFilterStatuses(filterStatuses);
    } catch {
      setFilterStatuses([defaultFilterStatus]);
      enqueueNotification({ title: t('itemIssues:errors.errorGetItemCounts') }, { variant: 'error' });
    }
  }, [api, defaultFilterStatus, enqueueNotification, t]);

  useEffect(() => {
    getFilterOptions();
  }, [getFilterOptions]);

  useEffect(() => {
    if (!isFetchingFilters) {
      getItems();
    }
  }, [filters, getItems, isFetchingFilters, page, pageSize, search, sortModel]);

  useEffect(() => {
    if (!isFetchingFilters) {
      getIssueCounts();
    }
  }, [getIssueCounts, isFetchingFilters]);

  const handleCloseIssueHistoryModal = useCallback(() => {
    setIsIssueHistoryModalOpen(false);
    setSelectedItemIssue('');

    getItems();
    getIssueCounts();
  }, [getIssueCounts, getItems]);

  const handleOpenIssueHistoryModal = useCallback((itemId: string) => {
    setIsIssueHistoryModalOpen(true);
    setSelectedItemIssue(itemId);
  }, []);

  return (
    <ItemIssuesContext.Provider
      value={{
        filterOptions,
        filterStatuses,
        filters,
        isIssueHistoryModalOpen,
        isLoading,
        items,
        page,
        pageSize,
        quickFilter,
        search,
        selectedItemIssue,
        setFilters: handleSetFilters,
        setIssueHistoryModalClose: handleCloseIssueHistoryModal,
        setIssueHistoryModalOpen: handleOpenIssueHistoryModal,
        setPage: handleSetPage,
        setPageSize: handleSetPageSize,
        setQuickFilter: handleSetQuickFilter,
        setSearch: handleSetSearch,
        setSortModel: handleSetSortModel,
        sortModel,
        totalItemsCount
      }}
    >
      {children}
    </ItemIssuesContext.Provider>
  );
};

export { ItemIssuesProvider };

export default ItemIssuesContext;
