import { LayoutHerodias } from '@lux-ds/content-layouts';
import Dialog from '@lux-ds/dialog';

import { css, styled } from 'utils/styles';

export const StyledDialog = styled(Dialog)(
  ({ theme }) => css`
    .MuiPaper-root {
      min-height: 643px;
      width: 936px;
      padding: ${theme.space.l};
    }
  `
);

export const StyledFooter = styled(LayoutHerodias)`
  align-items: flex-start;
  justify-content: space-between;
`;
