import Avatar from '@lux-ds/avatar';
import { LayoutHerodias, LayoutJay } from '@lux-ds/content-layouts';
import Dialog from '@lux-ds/dialog';
import StatusIndicator from '@lux-ds/status-indicator';
import Typography from '@lux-ds/typography';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

import { css, styled } from 'utils/styles';

export const StyledDialog = styled(Dialog)(
  ({ theme }) => css`
    .MuiPaper-root {
      min-height: 643px;
      width: 936px;
      padding: ${theme.space.l};
    }
  `
);

export const StyledHerodias = styled(LayoutHerodias)(
  ({ theme }) => css`
    align-items: flex-start;
    height: auto;
    padding-right: ${theme.space.s};
  `
);

export const StyledSeparator = styled(LayoutJay)(
  ({ theme }) => css`
    align-items: center;
    gap: ${theme.space.xs};
    height: 100%;
  `
);

export const StyledAvatar = styled(Avatar, { shouldForwardProp: prop => prop !== '$color' })<{ $color?: string }>(
  ({ $color, theme }) => css`
    ${$color
      ? css`
          background-color: ${$color};
        `
      : ''}

    font-size: 14px;
    height: ${theme.space.m};
    width: ${theme.space.m};
  `
);

export const StyledConnector = styled('span')(
  ({ theme }) => css`
    background-color: ${theme.palette.primary.shade[85]};
    flex-grow: 1;
    width: 1px;
  `
);

export const StyledTurnedInIcon = styled(AssignmentTurnedInIcon)(
  ({ theme }) => css`
    color: ${theme.palette.primary.shade[60]};
    height: 12px;
    width: 12px;
  `
);

export const StyledStatusIndicator = styled(StatusIndicator)(
  ({ theme }) => css`
    height: ${theme.space.m};
    padding: ${theme.space.xs};
  `
);

export const StyledContentHeader = styled(LayoutHerodias)(
  ({ theme }) => css`
    flex-wrap: wrap;
    gap: ${theme.space.xs};
    min-height: ${theme.space.m};
  `
);

export const StyledComment = styled(Typography)(
  ({ theme }) => css`
    background-color: ${theme.palette.primary.shade[98]};
    padding: ${theme.space.s};
  `
);
